import axios from "axios";
import reduxStore from "../store";

let API_URL;
if (location.hostname == "santiago.speedliv.com") {
  API_URL = "https://api-santiago.speedliv.com/api";
} 
else {
API_URL = "http://159.65.95.168:8616/api";
}
const token = window.localStorage.getItem("id_token");

const HTTP = axios.create({
  baseURL: API_URL,
  headers: {
    "X-Auth-Token": token,
  },
});

HTTP.interceptors.response.use(
  function (response) {
    // Do something with response data

    if (response.data && response.data.status == 401 && token) {
      window.localStorage.removeItem("id_token");
      window.location.href = "/";
    } else if (response.data && response.data.status == 400 && token) {
      alert(response.data.content);
    } else {
      const fe_version = response.headers["pragma"] || "default";

      if (
        token &&
        response.config &&
        response.config.method == "get" &&
        fe_version !== localStorage.getItem("pr-version")
      ) {
        //localStorage.setItem('pr-version', fe_version);
        reduxStore.dispatch("actionupdate", {
          update: true,
          pr_version: fe_version,
        });
        // window.location.reload() // For new version, simply reload on any get
      }
      return response;
    }
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default HTTP;
